html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Work Sans', 'Roboto', sans-serif;
  background-color: #f5f8fa;
}

html,
body,
#root {
  height: 100%;
}

/* Mod of: https://github.com/twbs/bootstrap/blob/v4.1.1/scss/_type.scss#L11 */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: 'Work Sans', 'Roboto', sans-serif;
  font-weight: 300;
  line-height: 1.1;
  color: inherit;
}
